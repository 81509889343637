/**
 * dynamically loads the state-specific functionality such as record book types
 * from the correct module, returning a promise of the result
 * @param stateId
 * @returns {Promise<T | never>}
 */
async function loadState(stateId) {
  switch (stateId) {
    case 'ex-st':
      return import('./example_state').then(e => e.default);
    case 'US-FL':
      return import('./florida').then(e => e.default);
    case 'US-ID':
      return import('./idaho').then(e => e.default);
    case 'US-ID-DEMO':
      return import('./idaho_demo').then(e => e.default);
    case 'US-KS':
      return import('./kansas').then(e => e.default);
    case 'US-AK':
      return import('./alaska').then(e => e.default);
    case 'US-MT':
      return import('./montana').then(e => e.default);
    case 'US-SC':
      return import('./south_carolina').then(e => e.default);
    case 'US-WV':
      return import('./west_virginia').then(e => e.default);
    case 'US-UT':
      return import('./utah').then(e => e.default);
    case 'US-MA':
      return import('./massachusetts').then(e => e.default);
    case 'US-IL':
      return import('./illinois').then(e => e.default);
    case 'US-PA':
      return import('./pennsylvania').then(e => e.default);
    case 'US-WY':
      return import('./wyoming').then(e => e.default);
    case 'US-HI':
      return import('./hawaii').then(e => e.default);
    case 'US-AR':
      return import('./arkansas').then(e => e.default);
    case 'US-TX':
      return import('./texas').then(e => e.default);
    case 'US-KY':
      return import('./kentucky').then(e => e.default);
    case 'US-OK':
      return import('./oklahoma').then(e => e.default);
    case 'US-NM':
      return import('./new_mexico').then(e => e.default);
    case 'US-AZ':
      return import('./arizona').then(e => e.default);
    case 'US-OR':
      return import('./oregon').then(e => e.default);
    case 'US-RI':
      return import('./rhode_island').then(e => e.default);
    case 'US-LA':
      return import('./louisiana').then(e => e.default);
    case 'US-CT':
      return import('./connecticut').then(e => e.default);
    case 'US-OH-CSU':
      return import('./ohio_csu').then(e => e.default);
    case 'US-ME':
      return import('./maine').then(e => e.default);
    case 'US-DE-DEMO':
      return import('./delaware').then(e => e.default);
    case 'US-DE':
      return import('./delaware').then(e => e.default);
    case 'US-CA-DEMO':
      return import('./california').then(e => e.default);
    case 'US-CA':
      return import('./california').then(e => e.default);
    default:
      return {projectTypes: []};
  }
}

export default loadState;