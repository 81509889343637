import React, {Component, lazy} from 'react';
import CustomSelectFormInput from '../form_data/custom_select_form_input';
import LoadingButton from '../loading_button';
import {Launch as ExternalLink, Lock, Warning} from '@material-ui/icons';
import SnackbarContent from '../../../vendor/components/Snackbar/SnackbarContent';
import {createURL, extractError, loadStripeInstance,} from '../../shared/shared_helpers';
import {CardElement, Elements, injectStripe, StripeProvider,} from 'react-stripe-elements';
import CustomInput from '../../../vendor/components/CustomInput/CustomInput';
import Button from '../../../vendor/components/CustomButtons/Button';
import {withStyles} from '@material-ui/core';
import {HouseholdAccount, Session} from '../app/types';
import {PaymentMethodSetting} from '../../services/backend-client';
import {confirmModal, runModalForm, runSweetAlert} from '../Modal/Modal';
import {RouterProps} from 'react-router';
import {FormData} from '../form_data';

const PaypalPayment = lazy(() => import('./paypal_payment'));
const BraintreePayment = lazy(() => import('./braintree_payment'));
const AuthNetPayment = lazy(() => import('./authorize_net_payment'));
const NelnetPayment = lazy(() => import('./nelnet_payment'));

export type PaymentSubmitData = { paymentMethod: string, token?: string }

interface Props extends RouterProps {
    session: Session,
    availableMethods?: PaymentMethodSetting[],
    amount: number,
    type?: 'ENROLLMENT' | 'EVENT'
    referenceData: Record<string, any>,
    onSubmit?: (paymentMethodData: PaymentSubmitData) => any,
    onUpdateTransactionFee?: (newFee: number) => void,
    description?: string,
    hostCountySetting?:any
}

type State = {
    paymentMethodType?: string,
    paymentMethod?: PaymentMethodSetting,
    error?: string,
    getStripeToken?: () => Promise<string>,
    submittingStripe?: boolean
}

class RenderPaymentMethods extends Component<Props, State> {
    componentDidMount() {
        const {availableMethods = []} = this.props;
        if (availableMethods.length === 1 && availableMethods[0]) {
            this.updateTransactionFee(availableMethods[0]);
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        const {availableMethods = []} = this.props;
        if (this.props.amount !== prevProps.amount &&
            availableMethods.length === 1 && availableMethods[0]) {
            this.updateTransactionFee(availableMethods[0]);
        }
    }

    updateTransactionFee(method: PaymentMethodSetting) {
        const {amount, onUpdateTransactionFee} = this.props;
        if (onUpdateTransactionFee) {
            // console.log('COMPUTING FEE', amount, method.txFixedFee, method.txPercentFee, this.getTransactionFee(method));
            onUpdateTransactionFee(this.getTransactionFee(method));
        }
    }

    getTransactionFee(method: PaymentMethodSetting) {
        const {amount} = this.props;
        if (amount <= 0) {
            return 0;
        }
        return Math.ceil(((method.txFixedFee || 0) +
            ((method.txPercentFee || 0) * amount)) * 100) / 100;
    }

    renderPaymentMethod(paymentMethod: PaymentMethodSetting) {
        const {session, referenceData, amount, type, history, hostCountySetting} = this.props,
            {backendClient, account} = session,
            household = account as HouseholdAccount,
            {error, submittingStripe} = this.state || {},
            amountWithFee = amount + this.getTransactionFee(paymentMethod);
        const creditAcctCode = referenceData?.state?.stateId === 'US-ID' ? referenceData?.detailCode : (paymentMethod.countyItemCodes || {})[referenceData.countyId];

        // console.log("DETAIL CODE", creditAcctCode);
        // console.log("REFERENCE DATA", referenceData);

        switch (paymentMethod.methodType?.toUpperCase()) {
            case 'STRIPE_CONNECT':
                if (!session.settings.county.countyEnrollmentFeeAccountId && !hostCountySetting?.countyEnrollmentFeeAccountId) {
                    return <SnackbarContent
                        message="County has not setup credit card payments. Please pay with cash or check to county office"
                        close
                        onClose={() => this.setState({
                            error: undefined,
                        })} icon={Warning} color="danger"/>;
                }
            // Fallthrough on purpose here
            // eslint-disable-next-line no-fallthrough
            case 'STRIPE':
                return <div>
                    <StripePaymentFormWrapper
                        session={session}
                        setCallback={(getStripeToken: any) =>
                            this.setState({getStripeToken})}/>

                    {error ? <div style={{marginTop: '1rem'}}>
                        <SnackbarContent message={error} close
                                         onClose={() => this.setState({
                                             error: undefined,
                                         })} icon={Warning} color="danger"/>
                    </div> : ''}
                    <div style={{textAlign: 'right'}}>
                        <LoadingButton key="complete" color="success"
                                       disabled={submittingStripe || !paymentMethod}
                                       onClick={this.onSubmitStripe.bind(this,
                                           paymentMethod)}>
                            <Lock/> Submit Payment
                        </LoadingButton>
                    </div>
                </div>;
            case 'BRAINTREE':
                return <BraintreePayment type={type} referenceData={referenceData}
                                         amount={amountWithFee}
                                         session={this.props.session}
                                         history={history}/>;
            case 'PAYPAL':
                return <PaypalPayment
                    type={type} referenceData={referenceData}
                    amountWithFee={amountWithFee} amountWithoutFee={amount}
                    session={this.props.session} history={history}
                    description={this.props.description}/>;

            case 'TOUCHNET':
                return <div style={{textAlign: 'right'}}>
                    <Button color="primary" onClick={async () => {
                        await confirmModal('Confirm Offsite Payment',
                            'You will be redirected to your payment provider to complete this transaction. Click Yes to continue');

                        // Remove old form
                        const oldForm = document.querySelector('#TOUCHNET_FORM');
                        if (oldForm && oldForm.parentNode) {
                            oldForm.parentNode.removeChild(oldForm);
                        }
                        // Create ticket
                        let ticketName = '';
                        if (type === 'ENROLLMENT') {
                            ticketName = `ENROLLMENT_${referenceData.enrollmentIds.join('_')}`;
                        } else if (type === 'EVENT') {
                            ticketName = `EVENT_${referenceData.registrationIds.join('_')}`;
                        } else {
                            throw new Error(`Unsupported type ${type}`);
                        }

                        // Get Touchnet Ticket
                        const {ticket} = await backendClient.touchnetGenerateTicket({
                            configId: session.settings.state.stateId,
                            returnTo: referenceData.returnTo || window.location.href,
                        }, {
                            ticketName, nameValuePairs: {
                                AMT: amountWithFee.toFixed(2), //.padStart(8, '0'),
                                BILL_NAME: `${household?.firstName} ${household?.lastName}`,
                                BILL_EMAIL_ADDRESS: household?.email || '',
                                BILL_STREET1: household?.address?.street1 || '',
                                BILL_STREET2: household?.address?.street2 || '',
                                BILL_CITY: household?.address?.city || '',
                                BILL_STATE: household?.address?.state || '',
                                BILL_POSTAL_CODE: household?.address?.zip || '',
                                BILL_COUNTRY: 'US',
                                //CREDIT_ACCT_CODE: (paymentMethod.countyItemCodes || {})[referenceData.countyId],
                                CREDIT_ACCT_CODE: creditAcctCode,
                                EXT_TRANS_ID: ticketName
                            },
                        });
                        if (!ticket) {
                            throw new Error('NO TICKET');
                        }
                        const form = document.createElement('FORM') as HTMLFormElement;
                        form.id = 'TOUCHNET_FORM';
                        form.action = paymentMethod.upayUrl; //'https://test.secure.touchnet.net:8443/C30002test_upay/web/index.jsp';
                        form.method = 'post';
                        const values = {
                            'TICKET': ticket,
                            'TICKET_NAME': ticketName,
                            UPAY_SITE_ID: paymentMethod.upaySiteId,
                        };
                        Object.entries(values).forEach(([name, value]) => {
                            const input = document.createElement('INPUT') as HTMLInputElement;
                            input.type = 'hidden';
                            input.name = name;
                            input.value = value;
                            form.append(input);
                        });
                        document.body.append(form);
                        form.submit();
                    }}>Pay with Touchnet</Button>
                </div>;
            case 'CASHNET':
                return <div style={{textAlign: 'right'}}>
                    <Button color="primary" onClick={async () => {
                        await confirmModal('Confirm Offsite Payment',
                            'You will be redirected to your payment provider to complete this transaction. Click Yes to continue');
                        const url = createURL(paymentMethod.cashnetUrl, {
                            itemcode: referenceData.itemCode || paymentMethod.cashnetItemCode,
                            amount: amountWithFee.toFixed(2),
                            USERID: account?.id,
                            NAME_G: `${household?.firstName || ''} ${household?.lastName || ''}`,
                            ADDR_G: `${household?.address?.street1 || ''} ${household?.address?.street2 || ''}`.trim(),
                            CITY_G: household?.address?.city || '',
                            STATE_G: household?.address?.state || '',
                            ZIP_G: household?.address?.zip || '',
                            EMAIL_G: household?.email || '',
                            REF1: type,
                            ...(referenceData.enrollmentIds ? {REF2: referenceData.enrollmentIds.join('_')} : {}),
                            ...(referenceData.registrationIds ? {REF2: referenceData.registrationIds.join('_')} : {})
                        });
                        // console.log('URL', url);
                        window.open(url, '_blank');
                    }}>Pay with Cashnet</Button>
                </div>;
            case 'AUTHORIZE_NET':
                return <AuthNetPayment loginId={paymentMethod.authNetAPILoginId}
                                       environment={paymentMethod.authNetEnv}
                                       publicKey={paymentMethod.authNetPublicKey}
                                       type={type} referenceData={referenceData}
                                       amount={amountWithFee} history={history}
                                       session={this.props.session}/>;
            case 'NELNET':
                /* TX fee will be computed and added on backend */
                return <NelnetPayment publicKey={paymentMethod.nelnetPublicKey}
                                      type={type} amount={amount} referenceData={referenceData}
                                      session={this.props.session} history={history}/>
            case 'EXTERNAL':
                return <div style={{textAlign: 'right'}}>
                    <p>{paymentMethod.externalLinkDesc}</p>
                    <Button color="primary" onClick={async () => {
                        await runModalForm(() => <div>
                            You are leaving ZSuite to an external payment system.<br/>
                            Payments may not be processed immediately.
                        </div>, {
                            title: 'Confirm Action',
                            noCancel: true,
                            submitLabel: 'Confirm and Continue',
                        });
                        window.location.href = paymentMethod.externalLinkUrl;
                    }}>
                        Continue to {paymentMethod.methodLabel} <ExternalLink/>
                    </Button>
                </div>;
        }
        return '';
    }

    async onSubmitStripe(paymentMethod: PaymentMethodSetting) {
        const {
                session: {backendClient, orgId, settings: {state, county}}, onSubmit = () => ({}),
                type, referenceData, amount, history, hostCountySetting
            } = this.props,
            {getStripeToken, submittingStripe} = this.state;

        if (!getStripeToken) {
            return this.setState({error: 'Error retrieving token'});
        }
        if (!state) {
            return this.setState({error: 'Error retrieving State Id'});
        }
        if(submittingStripe) {
            return;
        }
        await new Promise(resolve => this.setState({submittingStripe: true}, () => resolve(undefined)));
        let token;
        try {
            token = await getStripeToken();

            if (paymentMethod.methodType === 'STRIPE_CONNECT') {
                if (!county) {
                    return this.setState({error: 'Error retrieving County Id'});
                }

                await backendClient.processStripeConnectCheckout({}, {
                    stateId: state.stateId, countyId: hostCountySetting ? hostCountySetting?.countyId : county.countyId,
                    orgId, token, type: type as string,
                    enrollmentIds: referenceData.enrollmentIds,
                    registrationIds: referenceData.registrationIds,
                    amount,
                });
            }
        } catch (e) {
            this.setState({error: await extractError(e, 'Unknown Error'), submittingStripe: false});
            return;
        }

        try {
            let result;
            if (paymentMethod.methodType === 'STRIPE_CONNECT') {
                if (!this.props.onSubmit) {
                    history.push(referenceData.returnTo || '/');
                } else {
                    // Don't pass token since it has already been charged
                    result = onSubmit({paymentMethod: paymentMethod.methodType});
                }
            } else {
                //  Process 'STRIPE' method type
                //  TODO: move token processing into render_payment_methods for LMS course purchase and remove this else block
                result = onSubmit({paymentMethod: paymentMethod.methodType, token});
            }
            // await result to prevent setting submittingStripe to false too early
            if(result && result instanceof Promise) {
                await result;
            }
        } finally {
            this.setState({submittingStripe: false});
        }
    }

    render() {
        const {
                session: {
                    backendClient, settings: {disableEnrollmentPayments}
                },
                amount, type, referenceData, history
            } = this.props,
            paymentsDisabled = type === 'ENROLLMENT' && disableEnrollmentPayments,
            availableMethods = paymentsDisabled ? [] : (this.props.availableMethods || []);
        let {paymentMethod, paymentMethodType} = this.state || {};

        // Use Nop Checkout method if amount is zero
        if (amount <= 0) {
            return <div style={{textAlign: 'right'}}>
                <LoadingButton
                    key="complete" color="success"
                    onClick={async () => {
                        await backendClient.nopCheckout({}, {
                            type: type || '',
                            registrationIds: referenceData.registrationIds,
                            enrollmentIds: referenceData.enrollmentIds,
                        });
                        await runSweetAlert({
                            success: true,
                            title: 'Success!',
                            body: 'Payment successfully submitted',
                        }).catch(() => {});
                        history.push(referenceData.returnTo || '/');
                    }}>
                    <Lock/> Continue
                </LoadingButton>
            </div>;
        }

        if (!availableMethods.length) {
            return <div style={{marginTop: '2rem'}}>
                <SnackbarContent
                    message="Online Payments are currently unavailable. Please pay in-person or via check at your local 4-H Office"
                    icon={Warning} color="warning"/>
            </div>;
        }
        if (paymentMethodType) {
            paymentMethod = availableMethods.find(m =>
                m.methodType === paymentMethodType);
        }
        if (!paymentMethod && availableMethods.length === 1) {
            paymentMethod = availableMethods[0];
        }

        return (
            <div>
                {availableMethods.length > 1 ?
                    <FormData data={{paymentMethodType}} onChange={(s: any) => {
                        if (s.paymentMethodType) {
                            const paymentMethod = availableMethods.find(
                                m => m.methodType === s.paymentMethodType);
                            if (paymentMethod) {
                                this.updateTransactionFee(paymentMethod);
                            }
                        }
                        this.setState(s);
                    }}>
                        <CustomSelectFormInput id="paymentMethodType"
                                               labelText="Payment Method"
                                               options={availableMethods.map(m => ({
                                                   label: m.methodLabel || m.methodType,
                                                   value: m.methodType,
                                               }))}/>
                    </FormData> : ''}

                {paymentMethod ? this.renderPaymentMethod(paymentMethod) : ''}
            </div>
        );
    }
}

export default RenderPaymentMethods;

class StripePaymentFormWrapper
    extends Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {stripe: null, data: {}};
    }

    async componentDidMount() {
        const {session: {backendClient}} = this.props;
        this.setState({stripe: await loadStripeInstance(backendClient)});
    }

    render() {
        return <StripeProvider stripe={this.state.stripe}>
            <Elements>
                <StripePaymentFormComp
                    setCallback={this.props.setCallback}
                    classes={this.props.classes}
                    data={this.state.data}
                    onChange={(data: any) => this.setState(
                        (s: any) => ({data: Object.assign({}, s.data, data)}))}
                />
            </Elements>
        </StripeProvider>;
    }
}

const stripeFormStyles = {
    cardInfo: {
        '& label': {
            display: 'inline-block',
            paddingBottom: '1rem',
        },
    },
    table: {
        marginTop: '3rem',
    },
};

class StripePaymentForm extends Component<any, any> {
    componentDidMount() {
        if (this.props.setCallback) {
            this.props.setCallback(this.getFormData.bind(this));
        }
    }

    async getFormData() {
        const {data} = this.props;
        if (!data.cardholderName) {
            throw new Error('Card holder name is required');
        }
        let resp = await this.props.stripe.createToken({name: data.cardholderName});
        if (!resp.token) {
            throw new Error(
                resp.error ? resp.error.message : 'Card information is invalid');
        }
        return resp.token.id;
    }

    render() {
        const {onChange, classes, data} = this.props;

        return (<div>
                <div className={classes.cardInfo}>
                    <CardElement/>
                </div>
                <div style={{marginTop: '0.75rem', marginBottom: '3rem'}}>
                    <CustomInput
                        labelText={<span>Cardholder Name</span>}
                        helperText="Name as appears on card"
                        id="cardholderName"
                        formControlProps={{fullWidth: true}}
                        inputProps={{
                            value: data.cardholderName || '',
                            onChange: (e: any) => onChange(
                                {cardholderName: e.target.value}),
                        }}
                    />
                </div>
            </div>
        );
    }
}

const StripePaymentFormComp = injectStripe(
    withStyles(stripeFormStyles as any)(StripePaymentForm) as any) as any;