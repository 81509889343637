import React, { Component, Suspense, useState } from 'react';
// material-ui components
import withStyles from '@material-ui/core/styles/withStyles';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// @material-ui/icons
import Close from '@material-ui/icons/Close';
// core components
import Button from '../../../vendor/components/CustomButtons/Button';

import modalStyle from '../../../vendor/assets/jss/material-dashboard-pro-react/modalStyle';
import * as ReactDOM from 'react-dom';
import Dialog from '@material-ui/core/Dialog';
import CustomInput from '../../../vendor/components/CustomInput/CustomInput';
import SnackbarContent from '../../../vendor/components/Snackbar/SnackbarContent';
import { Email, HelpOutline, Phone, Warning } from '@material-ui/icons';
import LoadingButton from '../loading_button';
import { extractError } from '../../shared/shared_helpers';
import Spinner from '../spinner';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Snackbar from '../../../vendor/components/Snackbar/Snackbar';
import SessionContext from '../../session_context';
import { FormData } from '../form_data';
import moment from 'moment';
import SingleCheckbox from '../form_data/single_checkbox';

export function runModal(Component, opts = {}) {
  return new Promise((resolveOuterPromise, rejectOuterPromise) => {
    let modalRoot = window.document.querySelector('#modal');
    if (!modalRoot) {
      modalRoot = window.document.createElement('div');
      modalRoot.setAttribute('id', 'modal');
      window.document.body.appendChild(modalRoot);
    }

    let ModalComponent = withStyles(modalStyle)(
        class extends React.Component {
          constructor(props) {
            super(props);
            this.state = {open: true, closeable: !opts.notClosable};
            this.childComp = React.createRef();
          }

          resolve(result) {
            this.setState({open: false, closable: true});
            resolveOuterPromise(result);
          }

          reject() {
            this.setState({open: false, closable: true});
            rejectOuterPromise();
          }

          toggleCloseable() {
            this.setState({closeable: false});
          }

          render() {
            const props = this.props, {open, closeable, error} = this.state;
            const comp = <Component {...(opts.extraProps || {})} {...props}
                                    resolve={this.resolve.bind(this)}
                                    reject={this.reject.bind(this)}
                                    toggleCloseable={this.toggleCloseable.bind(
                                        this)}/>;
            return <Modal
                show={open}
                title={opts.title}
                actions={typeof opts.actions === 'function'
                    ? opts.actions({
                      resolve: this.resolve.bind(this),
                      reject: this.reject.bind(this),
                      setError: error => this.setState({error}),
                      comp: this.childComp,
                    }) : opts.actions}
                onClose={this.reject.bind(this)}
                closeable={closeable}
                size={opts.size}
                {...props}>
              <div style={{minWidth: 280}}>
                {opts.session ? <SessionContext.Provider value={opts.session}>{comp}</SessionContext.Provider> : comp}
              </div>
              {error ? <div style={{marginTop: '1rem'}}>
                <SnackbarContent message={error} close
                                 onClose={() => this.setState(
                                     {error: undefined})}
                                 icon={Warning} color="danger"/>
              </div> : ''}
            </Modal>;
          }
        });
    ReactDOM.render(<ModalComponent/>, modalRoot);
  });
}

/**
 * Runs a snackbar notification in the bottom left of the screen
 * @param {string} props.message - Message to be displayed
 * @param {'info'|'success'|'warning'|'danger'|'primary'|'rose'} [props.color='info'] - color of notification
 * @param {'tl'|'tr'|'tc'|'br'|'bl'|'bc'} [props.place='bl'] - location of notification
 * @param {number} [props.timeout=3000] - Length of the notification
 */
export function runSnackbarAlert(props) {
  let snackRoot = window.document.querySelector('#snack');
  if (!snackRoot) {
    snackRoot = window.document.createElement('div');
    snackRoot.setAttribute('id', 'snack');
    window.document.body.appendChild(snackRoot);
  }

  let SnackbarComponent = props => {
    const [open, setOpen] = useState(true);
    setTimeout(() => setOpen(false), props.timeout || 3000);
    return <Snackbar
        open={open}
        place={props.place || 'bl'}
        color={props.color || 'info'}
        close
        closeNotification={() => setOpen(false)}
        message={props.message}/>;
  };
  ReactDOM.render(<SnackbarComponent {...props}/>, snackRoot);
}

export async function runSweetAlert(props) {
  const [SweetAlert, buttonStyle] = await Promise.all([
    import('react-bootstrap-sweetalert').then(m => m.default),
    import('../../../vendor/assets/jss/material-dashboard-pro-react/components/buttonStyle')
        .then(m => m.default),
  ]);
  return await new Promise((resolve, reject) => {
    let modalRoot = window.document.querySelector('#modal');
    if (!modalRoot) {
      modalRoot = window.document.createElement('div');
      modalRoot.setAttribute('id', 'modal');
      window.document.body.appendChild(modalRoot);
    }

    let ModalComponent = withStyles(buttonStyle)(
        class extends React.Component {
          constructor(props) {
            super(props);
            this.state = {open: true};
          }

          resolve(result) {
            this.setState({open: false});
            resolve(result);
          }

          reject() {
            this.setState({open: false});
            reject();
          }

          render() {
            const {classes} = this.props;
            let buttonClass = '';
            if (props.success) {
              buttonClass = classes.success;
            } else if (props.error) {
              buttonClass = classes.danger;
            } else if (props.info) {
              buttonClass = classes.info;
            } else if (props.warning) {
              buttonClass = classes.warning;
            } else if (props.primary) {
              buttonClass = classes.primary;
            }
            return this.state.open &&
                <SweetAlert {...props}
                            onConfirm={() => this.resolve()}
                            onCancel={() => this.reject()}
                            confirmBtnCssClass={
                              classes.button + ' ' +
                              buttonClass
                            }>
                  {props.body}
                </SweetAlert>;
          }
        });
    ReactDOM.render(<ModalComponent/>, modalRoot);
  });
}

export async function runSystemAlert(props) {
  const [SweetAlert, buttonStyle] = await Promise.all([
    import('react-bootstrap-sweetalert').then(m => m.default),
    import('../../../vendor/assets/jss/material-dashboard-pro-react/components/buttonStyle')
        .then(m => m.default),
  ]);
  return await new Promise((resolve, reject) => {
    let modalRoot = window.document.querySelector('#modal');
    if (!modalRoot) {
      modalRoot = window.document.createElement('div');
      modalRoot.setAttribute('id', 'modal');
      window.document.body.appendChild(modalRoot);
    }

    let ModalComponent = withStyles(buttonStyle)(
        class extends React.Component {
          constructor(props) {
            super(props);
            this.state = {open: true};
          }

          resolve(result) {
            this.setState({open: false});
            resolve(result);
          }

          reject() {
            this.setState({open: false});
            reject();
          }

          render() {
            const {classes} = this.props;
            let buttonClass = '';
            if (props.success) {
              buttonClass = classes.success;
            } else if (props.error) {
              buttonClass = classes.danger;
            } else if (props.info) {
              buttonClass = classes.info;
            } else if (props.warning) {
              buttonClass = classes.warning;
            } else if (props.primary) {
              buttonClass = classes.primary;
            }
            return this.state.open &&
                <SweetAlert {...props}
                            onConfirm={() => this.resolve()}
                            onCancel={() => this.reject()}
                            confirmBtnCssClass={
                              classes.button + ' ' +
                              buttonClass
                            }>
                  {props.body}
                </SweetAlert>;
          }
        });
    ReactDOM.render(<ModalComponent/>, modalRoot);
  });
}

export function promptModal(title, prefill = '') {
  let value = prefill, resolve;
  return runModal(class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {value};
      resolve = props.resolve;
    }

    render() {
      return (
          <CustomInput formControlProps={{fullWidth: true}}
                       inputProps={{
                         value: this.state.value,
                         onChange: e => {
                           value = e.target.value;
                           this.setState({value: e.target.value});
                         },
                       }}
          />
      );
    }
  }, {
    title,
    actions: <Button onClick={() => resolve(value)}
                     color="success">OK</Button>,
  });
}

export function confirmModal(
    title = 'Confirm', body = 'Are you sure?', confirmColor = 'primary') {
  let resolve, reject;
  return runModal(class extends React.Component {
    constructor(props) {
      super(props);
      resolve = props.resolve;
      reject = props.reject;
    }

    render() {
      return body || '';
    }
  }, {
    title, actions: [
      <Button key="cancel" onClick={() => reject()} simple color="primary">
        Cancel</Button>,
      <Button key="ok" onClick={() => resolve(true)}
              color={confirmColor}>Yes</Button>,
    ],
  });
}

export function alertModal(title, body) {
  let resolve;
  return runModal(class extends React.Component {
    componentDidMount() {
      resolve = this.props.resolve;
    }

    render() {
      return body;
    }
  }, {
    title,
    actions: [
      <Button key="ok" onClick={() => resolve()} color="success">Ok</Button>],
  });
}

export class HelpModal extends Component {
  render() {
    const {children, title = 'Help', color = 'primary'} = this.props;
    return (
        <Button justIcon simple round color={color} size="sm"
                component="a" style={{margin: '0 0 4px 0'}}
                onClick={() => alertModal(title, children).catch(() => {
                })}>
          <HelpOutline/>
        </Button>
    );
  }
}

export async function showContactModal(contact, e) {
  await runModal(() => <div style={{minWidth: 480}}>
    {contact.email ? <div>Email: <h4>{contact.email}</h4></div> : ''}
    {contact.phone ? <div>Phone: <h4>{contact.phone}</h4></div> : ''}
  </div>, {
    title: contact.name, actions: ({resolve}) => [
      contact.phone ?
          <Button component="a" key="phone"
                  href={'tel://' + contact.phone}
                  color="primary" onClick={resolve}>
            <Phone/> Call
          </Button> : '',
      contact.email ? <Button key="email" component="a"
                              href={'mailto://' + contact.email}
                              color="primary"
                              onClick={resolve}>
        <Email/> Send Email
      </Button> : '',
    ],
  }).catch(() => {
  });
}

/**
 * Runs a modal that can have a showUntil date and a doNotShow setting
 *
 * @param Component - Body of the modal
 * @param props
 * @param {string} props.id - key to use in the informationModals settings object
 * @param {Session} props.session - Session needed for updating settings and showing conditionally based on settings
 * @param {string} props.showUntil - Date to show the modal (if not set in settings) until
 * @param {any} props.modalOpts - additional options to be set on the modal
 * @returns {Promise<void>}
 */
export async function runInformationModal(Component, props) {
  const doNotShow = props.session.settings.informationModals?.[props.id];
  if (moment().isAfter(props.showUntil) || doNotShow) return;

  async function updateSettings({doNotShow}) {
    const {id, session, session: {account, backendClient}} = props;
    if (doNotShow) {
      await backendClient.updateSettings({accountId: account?.id}, {
        informationModals: {
          ...props.session.settings.informationModals,
          [id]: doNotShow
        }
      });
      await session.getSessionService().reloadSession();
    }
  }

  await runModalForm(() => <>
    <Component/>
    <SingleCheckbox id='doNotShow' label='Do not show this message again'/>
  </>, {
    title: 'Information',
    ...props.modalOpts,
    initialState: {doNotShow: true},
    noCancel: true,
    onSubmit: updateSettings
  });
}

/**
 * Run a form component within a <FormData> resolves with form after
 *
 * @param Component
 * @param {Object} opts
 * @param {string} opts.title - Title of the modal
 * @param {string} [opts.submitLabel='Submit'] - Submit button label
 * @param {string} [opts.cancelLabel='Cancel'] - Cancel button label
 * @param {boolean} [opts.noCancel=false] - Removes the cancel button
 * @param {boolean} [opts.noSubmit=false] - Removes the submit button
 * @param {function|undefined} [opts.onSubmit] - Function called when form is submitted
 * @param {function|undefined} [opts.onCancel] - Function called when form is cancelled
 * @param {string} [opts.defaultError='An Error has occurred'] - Default error to show when error is thrown during onSubmit function
 * @param {'xs'|'sm'|'md'|'lg'|'xl'} [opts.size] - maximum width of the modal
 * @param {boolean} [opts.fullWidth] - if set, forces the modal to be max width
 * @param {'paper'|'body'} [opts.scroll] - type of scroll on the modal
 * @param {Object} [opts.initialState={}] - Initial state of the form inputs
 * @param {function} [opts.onChange] - Function to be called when the form is changed
 * @param {function|undefined} [opts.additionalActions] - additional buttons to be rendered at the bottom
 * @param {boolean} [opts.notClosable=false] - makes the modal not closeable, including clicking outside of the modal
 * @param {Promise} [opts.wrapPromise] - If set, waits for this promise to be resolved before closing
 * @param [opts.session] - If set, wraps the children of the modal component in a SessionContext.Provider
 *
 * @returns {Promise}
 */
export function runModalForm(Component, opts = {}) {
  return new Promise((resolve, reject) => {
    let modalRoot = window.document.querySelector('#modal');
    if (!modalRoot) {
      modalRoot = window.document.createElement('div');
      modalRoot.setAttribute('id', 'modal');
      window.document.body.appendChild(modalRoot);
    }

    const {
      title,
      submitLabel = 'Submit',
      cancelLabel = 'Cancel',
      noCancel = false,
      noSubmit = false,
      onSubmit = x => x,
      onCancel = () => {
      },
      defaultError = 'An Error has occurred',
      size,
      fullWidth,
      scroll,
      initialState = {},
      onChange = x => x,
      additionalActions = () => [],
      notClosable = false,
      wrapPromise,
      session
    } = opts;

    let ModalComponent = withStyles(modalStyle)(
        class extends React.Component {
          constructor(props) {
            super(props);
            this.state = {...initialState, _open: true, closeable: !notClosable};
            this.onSubmit = this.onSubmit.bind(this);
            this.resolve = this.resolve.bind(this);
            this.reject = this.reject.bind(this);
          }

          componentDidMount() {
            if (wrapPromise && wrapPromise instanceof Promise) {
              wrapPromise.then(this.resolve, this.reject);
            }
          }

          resolve(result) {
            this.setState({_open: false});
            resolve(result);
          }

          reject() {
            this.setState({_open: false});
            onCancel();
            reject();
          }

          async onSubmit() {
            try {
              const state = Object.keys(this.state)
                  .filter(k => ['_open', 'closeable'].indexOf(k) === -1)
                  .reduce((obj, k) => ({...obj, [k]: this.state[k]}), {});
              const result = onSubmit(state);
              if (result instanceof Promise) {
                this.resolve(await result);
              } else {
                this.resolve(result);
              }
            } catch (e) {
              console.error(e);
              this.setState({
                error: await extractError(e, defaultError),
              });
            }
          }

          render() {
            const props = this.props,
                {_open, closeable, error} = this.state;
            return <Modal
                show={_open}
                title={title}
                actions={<div>
                  {additionalActions({
                    data: this.state || {},
                    onSubmit: onSubmit,
                    resolve: this.resolve.bind(this),
                    reject: this.reject.bind(this),
                  })}
                  {noCancel ? '' :
                      <Button color="primary" simple onClick={this.reject}>
                        {cancelLabel}
                      </Button>}
                  {noSubmit ? '' :
                      <LoadingButton color="primary" onClick={this.onSubmit}>
                        {submitLabel}
                      </LoadingButton>}
                </div>}
                onClose={this.reject.bind(this)}
                closeable={closeable}
                size={size}
                fullWidth={fullWidth}
                scroll={scroll}
                {...props}>
              <form onSubmit={e => {
                e.preventDefault();
                if(noSubmit) {
                  return this.onSubmit();
                } else {
                  return false;
                }
              }}>
                <FormData data={this.state || {}}
                          onChange={newData => this.setState(
                              onChange({...(this.state || {}), ...newData}))}>
                  <Suspense fallback={<Spinner/>}>
                    {session
                        ? <SessionContext.Provider value={session}><Component onSubmit={this.onSubmit}/></SessionContext.Provider>
                        : <Component onSubmit={this.onSubmit}/>}
                  </Suspense>

                  {error ? <div style={{marginTop: '1rem'}}>
                    <SnackbarContent message={error} close
                                     onClose={() => this.setState(
                                         {error: undefined})}
                                     icon={Warning} color="danger"/>
                  </div> : ''}
                </FormData>
              </form>
            </Modal>;
          }
        });

    ReactDOM.render(<ModalComponent/>, modalRoot);
  });
}

class _Modal extends React.Component {

  render() {
    const {
      classes, title, children, show, onClose, closeable, actions, scroll,
      size, fullWidth,
    } = this.props;

    return (
        <Dialog
            fullWidth={fullWidth}
            classes={{
              root: classes.center,
              paper: classes.paper,
            }}
            maxWidth={size}
            open={show}
            scroll={scroll || 'body'}
            onClose={closeable && onClose}
            disableBackdropClick={!closeable}
            disableEscapeKeyDown={!closeable}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-title">
          <DialogTitle
              id="scroll-dialog-title"
              disableTypography
              className={classes.modalHeader}>
            {closeable && <Button
                justIcon
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={onClose}
            >
              <Close className={classes.modalClose}/>
            </Button>}
            <h4 className={classes.modalTitle}>{title}</h4>
          </DialogTitle>
          <DialogContent
              id="scroll-dialog-description"
              className={classes.modalBody}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              {children}
            </MuiPickersUtilsProvider>
          </DialogContent>
          {actions && <DialogActions
              className={classes.modalFooter + ' ' +
              classes.modalFooterCenter}>
            {actions}
          </DialogActions>}
        </Dialog>
    );
  }
}

const styles = theme => ({
  ...modalStyle(theme),
  modal: {
    ...modalStyle(theme).modal,
    width: 480, maxWidth: '100%',
  },
  modalSm: {
    ...modalStyle(theme).modal,
    width: 280, maxWidth: '100%',
  },
  modalLg: {
    width: '100%',
    maxWidth: '680px !important',
  },
  modalCloseButton: {
    float: 'right',
  },
});

export const Modal = withStyles(styles)(_Modal);
export default Modal;
