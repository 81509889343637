import React from 'react';
import Button from '../../../vendor/components/CustomButtons/Button';
import { Add, Check, Delete, Edit, ExpandMore, FileCopy, Visibility } from '@material-ui/icons';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import SortableTree, {
  addNodeUnderParent,
  changeNodeAtPath,
  find,
  getVisibleNodeCount,
  removeNodeAtPath,
  // @ts-ignore
} from 'react-sortable-tree';
import Theme from '../../shared/react_sortable_themes/minimal_theme';
import { confirmModal, runModalForm } from '../Modal/Modal';
import { formatMoney, toCamelCase } from '../../shared/shared_helpers';
import { withStyles, WithStyles } from '@material-ui/core';
import styles from '../../../vendor/assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch';
import dashboardStyle from '../dashboard/dashboardStyle';
import CustomFormInput from '../form_data/custom_form_input';

import './custom_form_editor.css';
import CustomSelectFormInput from '../form_data/custom_select_form_input';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import TableEdit from '../table_edit';
import RenderCustomForm from './render_custom_form';
import SingleCheckbox from '../form_data/single_checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import { FormDataContext, WithFormProp } from '../form_data';
import { withSession } from '../../session_context';

type CategoryValidation = {
  index: number,
  type: string
};

interface TreeNode {
  multiSelectLimit: number;
  title: string,
  subtitle: string,
  helpText?: string,
  key: string,
  type: 'text' | 'select' | 'multi-select' | 'category' | 'option' | 'paid-option' | 'date' | 'sub-select' | 'file',
  fixed?: boolean,
  autofill?: boolean,
  validator?: string,
  subFieldLabel?: string,
  subFieldKey?: string,
  paidOptionAmount?: number,
  categoryValidations: CategoryValidation[],
  isRequired: boolean,
  children: FormField[],
  isConditionallyVisible: {
    visibleFieldId: string,
    visibleFieldValue: string,
  },
  isConditionallyRequired: {
    requiredFieldId: string,
    requiredFieldValue: string,
  },
  hideField: boolean,
  isSignatureDate: boolean,
  fieldVisibility: string,
  whoCanEditField: string,
}

export interface FormField extends TreeNode {
  accept: string[];
  multiSelectLimit: number;
  type: 'text' | 'select' | 'multi-select' | 'date' | 'sub-select' | 'file';
  fieldMeta: Record<string, any>;
  optionSource?: string;
}

export interface FormCategory extends TreeNode {
  type: 'category'
}

export interface Option {
  title: string
  optionLabel?: string // Will override the title if specified
  type: 'option' | 'paid-option' | 'multi-select'
  subFieldLabel?: string
  paidOptionAmount?: string
  fieldMeta: Record<string, any>
  disabled?: boolean
}

interface Props extends WithStyles {
  session: any,
  value: FormCategory[],
  classes: any,
  showPaidOptions?: boolean,

  onChange(newValue: FormCategory[]): void,

  // Gets meta-data fields for a form field (extra attributes such as mapping to an ES237 report)
  getMetaFields?: (field: FormField | Option) => FormField[],
}

export type CustomForm = FormCategory[];
type NodeInfo = { node: TreeNode, path: number[] | string[], parentNode?: TreeNode };

const style = {
  ...dashboardStyle,
  ...styles,
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center',
  },
  inputAdornmentIcon: {
    color: '#555',
  },
  inputAdornment: {
    position: 'relative',
  },
};

class CustomFormEditor extends React.Component<Props, any> {

  /**
   * isUniqueKey is used to determine if we've already used a key somewhere else in the tree
   *
   * @param key - key to check
   */
  isUniqueKey(key: string) {
    let isDuplicate = (this.props.value || []).reduce((acc, formCategory) => {
      if (acc) {
        return true;
      }
      return (formCategory.children || []).some(f => f.key === key);
    }, false);
    return !isDuplicate;
  }

  /**
   * editCategory is used for both editing and creating new categories
   *
   * @param title - the title of the modal. Usually 'Edit Category' if editing, or 'Add Category' if not
   * @param treeData - the entire tree data
   * @param nodeInfo - the node data of the category you're editing. If set, puts the function in "editing" mode
   * @param save - function for saving the data of the new/revised category
   */
  async editCategory({title, treeData, nodeInfo, save}:
                         { title: string, treeData: CustomForm, nodeInfo?: NodeInfo, save: any }) {
    const newCategory = await runModalForm(() => <FormDataContext.Consumer>{
      ({data, onChange}: any) => {
        if (nodeInfo) {
          const fieldOptions = treeData.map(c => (c.children || []).map(child => ({
                name: child.key,
                id: child.key,
                validator: child.validator
              }))).reduce((a, b) => a.concat(b), []),
              validations = data.validations || [];

          return <div style={{width: 680, maxWidth: '100%'}}>
            <CustomFormInput id="name" labelText="Name"/>
            {nodeInfo.node.type !== 'option' ? <ExpansionPanel variant="outlined">
              <ExpansionPanelSummary
                  expandIcon={<ExpandMore/>}
                  aria-controls="validations-panel"
                  id="validations-header">
                <Typography>Validations</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{overflow: 'auto'}}>
                <TableEdit cols={[
                  {
                    key: 'type', label: 'Type', editable: true,
                    type: 'enum', values: {FIELD_SUM: 'Field Sum'},
                    format(_: any, {type, fieldIds, targetFieldIds}: any) {
                      if (type === 'FIELD_SUM') {
                        return '(' + fieldIds.map((id: string) =>
                                fieldOptions.find(f => f.id === id)?.name).join('+') + ') = (' +
                            targetFieldIds.map((id: string) =>
                                fieldOptions.find(f => f.id === id)?.name).join('+') + ')';
                      }
                    }
                  },
                  {
                    key: 'fieldIds', label: 'Field(s) to Sum', formOnly: true,
                    editable({type}: CategoryValidation) {
                      return type === 'FIELD_SUM';
                    },
                    type: 'select_multiple', values: fieldOptions
                        .filter(o => o.validator === 'numeric')
                  },
                  {
                    key: 'targetFieldIds', label: 'Field(s) to Match Sum', formOnly: true,
                    editable({type}: CategoryValidation) {
                      return type === 'FIELD_SUM';
                    },
                    type: 'select_multiple', values: fieldOptions
                        .filter(o => o.validator === 'numeric')
                  }
                ]}
                           rows={validations} title="Validation"
                           onCreateRow={(row: CategoryValidation) => onChange({
                             validations: row.index === 0
                                 // Fixes issue where table edit calls onCreate when index is zero
                                 // can be removed after fixed
                                 ? validations.map((v: CategoryValidation, i: number) =>
                                     i === row.index ? row : v)
                                 : validations.concat({
                                   ...row, index: validations.length
                                 })
                           })}
                           onSaveRow={(row: CategoryValidation) => onChange({
                             validations: validations
                                 .map((v: CategoryValidation, i: number) =>
                                     i === row.index ? row : v)
                           })}
                           onRemoveRow={(row: CategoryValidation) => onChange({
                             validations: validations
                                 .filter((v: CategoryValidation) => v.index !== row.index)
                                 .map((v: CategoryValidation, i: number) => ({...v, index: i}))
                           })}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel> : ''}
          </div>;
        } else {
          return <div style={{width: 680, maxWidth: '100%'}}>
            <CustomFormInput id='name' labelText='Name'/>
          </div>
        }
      }
    }</FormDataContext.Consumer>, {
      title,
      submitLabel: nodeInfo ? 'Save' : 'Add',
      onSubmit: (o: any) => {
        // Only check if the name is unique if we're adding a new category
        // or if we're editing a category and they've changed the name
        if ((!nodeInfo || o.name !== nodeInfo.node.title) && !this.isUniqueKey(toCamelCase(o.name))) {
          throw new Error('This field cannot be generated because it is identical to another already created field. Please try again with a unique name.')
        }
        return o;
      },
      size: 'xl',
      initialState: {
        name: nodeInfo?.node.title,
        validations: nodeInfo?.node.categoryValidations
      }
    }).catch(() => {
    });

    if (newCategory) {
      // We update the node if there was an initial node provided, otherwise we just create a new node
      if (nodeInfo) {
        const results = changeNodeAtPath({
          treeData,
          getNodeKey: ({node}: { node: TreeNode }) => node.key,
          path: nodeInfo.path,
          newNode: {
            ...nodeInfo.node,
            title: newCategory.name,
            key: nodeInfo.node.key || toCamelCase(newCategory.name),
            categoryValidations: newCategory.validations
          },
        });
        save(results);
      } else {
        const results = addNodeUnderParent({
          treeData,
          getNodeKey: ({node}: { node: TreeNode }) => node.key,
          newNode: {
            title: newCategory.name,
            subtitle: 'Type: category',
            key: toCamelCase(newCategory.name),
            type: 'category',
            children: [],
            expanded: false,
          },
        });
        save(results.treeData);
      }
    }
  }

  /**
   * deleteCategory is used to delete a category based on a specific node. This method removes the category as well as
   * every input field beneath it
   *
   * @param treeData - the entire tree data
   * @param nodeInfo - the node object of the node we're deleting. Requires the path generated by react-sortable-tree to work
   * @param save - function for saving the new tree with the removed category
   */
  deleteCategory({treeData, nodeInfo, save}: { treeData: CustomForm, nodeInfo: NodeInfo, save: any }) {
    return confirmModal('Confirm',
        'Are you sure you want to delete this section and all of its settings?')
        .then(_ => {
          treeData = removeNodeAtPath({
            treeData,
            getNodeKey: ({node}: { node: TreeNode }) => node.key,
            path: nodeInfo.path,
          });
          save(treeData);
          this.setState({dirty: true});
        });
  }

  /**
   * editFormField is used for both editing and adding a new input field
   *
   * @param title - the title of the modal. Usually 'Edit Field' if editing, or 'Add Field' if not
   * @param treeData - the entire tree data
   * @param parentNodeInfo - the node data of the parent node. Usually a category, but if the field is a sub-select, it will be a select node
   * @param nodeInfo - the node data of the field you're editing. If set, puts the function in "editing" mode
   * @param save - function for saving the data of the new/revised field
   */
  async editFormField({title, treeData, parentNodeInfo, nodeInfo, save}:
                          {
                            title: string,
                            treeData: CustomForm,
                            parentNodeInfo?: NodeInfo,
                            nodeInfo?: NodeInfo,
                            save: any
                          }) {

    const pageLink = window.location.href || '';

    const newField = await runModalForm(() =>
        <FormDataContext.Consumer>{({data}: any) => {
          const requiredFieldNode = data.requiredFieldId ? findNode(treeData, data.requiredFieldId) : undefined,
              visibleFieldNode = data.visibleFieldId ? findNode(treeData, data.visibleFieldId) : undefined;
          return <>
            <CustomFormInput id="title" labelText="Title"/>
            <CustomFormInput id="helpText" labelText="Help Text (Optional)"/>
            <CustomFormInput id="key" labelText="Field ID (DO NOT CHANGE)"
                //disabled={parentNodeInfo?.node.fixed || nodeInfo?.parentNode?.fixed}
                             disabled={true}
            />
            <SingleCheckbox id="autofill" labelText="Autofill when available"/>
            {(nodeInfo?.node.type) === 'sub-select' ?
                <div>
                  <CustomFormInput id={'subFieldLabel'} labelText="Sub Select Label"/>
                  <CustomFormInput id={'subFieldKey'} labelText="Sub Select Key"/>
                </div> :
                <CustomSelectFormInput
                    id={'type'}
                    labelText="Input Type"
                    value='text'
                    options={[
                      {label: 'Text', value: 'text'},
                      {label: 'Single-Select', value: 'select'},
                      {label: 'Multi-Select', value: 'multi-select'},
                      {label: 'Date', value: 'date'},
                      {label: 'File', value: 'file'}
                    ]}/>
            }
            <CustomSelectFormInput id={'validator'} labelText="Validation" options={[
              {label: 'None', value: 'none'},
              {label: 'Numeric', value: 'numeric'},
              {label: 'Date', value: 'date'},
              {label: 'E-mail', value: 'email'},
              {label: 'Phone Number', value: 'phoneNumber'},
              ...(data.type === 'multi-select' ? [{
                label: 'Limit Number of Selections',
                value: 'selectionLimit'
              }] : []),
            ]}/>
            {data.type === 'multi-select' && data.validator === 'selectionLimit'
                ? <CustomFormInput id="multiSelectLimit" labelText="Multi-Select Limit"/> : ''}
            <SingleCheckbox id='isConditionallyVisible'
                            labelText='Is this Field Visible Based on Another Field'/>
            {data.isConditionallyVisible ? <>
              <CustomSelectFormInput id="visibleFieldId" labelText="Field the condition is based on"
                                     options={listAllFields(treeData).filter(f => !nodeInfo?.node?.key || f.id !== nodeInfo?.node?.key)}/>
              {visibleFieldNode?.type === 'select' || visibleFieldNode?.type === 'multi-select' || visibleFieldNode?.type === 'sub-select'
                  ? <CustomSelectFormInput id="visibleFieldValue"
                                           labelText="Field Value for showing additional field"
                                           options={visibleFieldNode.children.map((node) => node.title)}/>
                  : <CustomFormInput id="visibleFieldValue"
                                     labelText="Field Value for showing additional field"/>}
            </> : ''}
            <SingleCheckbox id="isConditionallyRequired"
                            labelText="Is this Field Required Based on Another Field"/>
            {data.isConditionallyRequired ? <>
              <CustomSelectFormInput id="requiredFieldId" labelText="Field the condition is based on"
                                     options={listAllFields(treeData).filter(f => !nodeInfo?.node?.key || f.id !== nodeInfo?.node?.key)}/>
              {requiredFieldNode?.type === 'select' || requiredFieldNode?.type === 'multi-select' || requiredFieldNode?.type === 'sub-select'
                  ? <CustomSelectFormInput id="requiredFieldValue"
                                           labelText="Field Value for requiring additional field"
                                           options={requiredFieldNode.children.map((node) => node.title)}/>
                  : <CustomFormInput id="requiredFieldValue"
                                     labelText="Field Value for requiring additional field"/>}
            </> : ''}

            <SingleCheckbox id='hideField' labelText='Hide Field'/>

            {data.type === 'date' ?
                <SingleCheckbox id='isSignatureDate' labelText='Is this date field a signature date?'/> : ''}


            {pageLink.includes('events') ? <>
              <CustomSelectFormInput id='fieldVisibility' labelText='Who is this field visible to?'
                                     options={['Internal Registrations', 'External Registrations', 'Both']}
              />
            </> : ''
            }

            {!pageLink.includes('events') ? <>
              <CustomSelectFormInput id='whoCanEditField' labelText='Who can edit this field?'
                                     helperText={"If this field is left blank, everyone can edit the field."}
                                     options={['Both Admins and Families', 'Admins Only']}
              />
            </> : ''
            }

            {['select', 'multi-select'].indexOf(data.type) > -1 ?
                <CustomFormInput id="optionSource" labelText="External Options Source ID"
                                 helperText={'Used for externally defined options'}/> : ''}


            <WithFormProp prop="fieldMeta">
              <RenderCustomForm
                  customForm={[{children: this.props.getMetaFields?.(data) || []} as FormCategory]}
                  defaultSm={12}/>
            </WithFormProp>
          </>
        }}</FormDataContext.Consumer>, {
      title,
      initialState: {
        ...nodeInfo?.node,
        isConditionallyVisible: (nodeInfo?.node.isConditionallyVisible?.visibleFieldId && nodeInfo?.node.isConditionallyVisible?.visibleFieldValue),
        isConditionallyRequired: (nodeInfo?.node.isConditionallyRequired?.requiredFieldId && nodeInfo?.node.isConditionallyRequired?.requiredFieldValue)
      },
      onSubmit: (data: any) => {
        // Only check if the name is unique if we're adding a new field
        // or if we're editing a field and they've changed the key
        // or if it's not a fixed field, which they can edit, but not change the key
        if ((!nodeInfo || data.key !== nodeInfo.node.key) && !this.isUniqueKey(data.key || toCamelCase(data.title))) {
          throw new Error('This field cannot be generated because it is identical to another already created field. Please try again with a separate title, or provide a unique Field Id.');
        }

        // This type: 'text' will be overridden with any type the use chooses in the previous form (with the ...data),
        // but if they don't choose a type, this will be the default
        return {
          type: 'text',
          ...data
        };
      },
      submitLabel: nodeInfo ? 'Save' : 'Add'
    }).catch(() => {
    });

    if (newField) {
      // If nodeInfo is set, then we want to update the previous node, otherwise we want to add a new node
      if (nodeInfo) {
        const results = changeNodeAtPath({
          treeData,
          getNodeKey: ({node}: { node: TreeNode }) => node.key,
          path: nodeInfo.path,
          newNode: {
            ...nodeInfo.node,
            ...newField,
            subtitle: 'Type: ' + newField.type,
            isConditionallyVisible: newField.isConditionallyVisible ? {
              visibleFieldId: newField.visibleFieldId,
              visibleFieldValue: newField.visibleFieldValue
            } : undefined,
            isConditionallyRequired: newField.isConditionallyRequired ? {
              requiredFieldId: newField.requiredFieldId,
              requiredFieldValue: newField.requiredFieldValue,
            } : undefined,
            hideField: newField.hideField,
            isSignatureDate: newField.isSignatureDate,
            fieldVisibility: newField.fieldVisibility,
            whoCanEditField: newField.whoCanEditField,
          },
        });
        save(results);
      } else {
        const results = addNodeUnderParent({
          treeData,
          newNode: {
            title: newField.title,
            subtitle: 'Type: ' + newField.type,
            helpText: newField.helpText || '',
            paidOptionAmount: newField.paidOptionAmount || "",
            key: newField.key || toCamelCase(newField.title),
            type: newField.type,
            isRequired: false,
            validator: newField.validator,
            autofill: newField.autofill,
            multiSelectLimit: newField.multiSelectLimit,
            isConditionallyVisible: newField.isConditionallyVisible ? {
              visibleFieldId: newField.visibleFieldId,
              visibleFieldValue: newField.visibleFieldValue
            } : undefined,
            isConditionallyRequired: newField.isConditionallyRequired ? {
              requiredFieldId: newField.requiredFieldId,
              requiredFieldValue: newField.requiredFieldValue,
            } : undefined,
            hideField: newField.hideField,
            isSignatureDate: newField.isSignatureDate,
            fieldVisibility: newField.fieldVisibility,
            whoCanEditField: newField.whoCanEditField,
          },
          parentKey: parentNodeInfo?.node.key,
          getNodeKey: ({node}: { node: TreeNode }) => node.key,
          expandParent: true,
        });
        save(results.treeData);
      }
    }
  }

  /**
   * duplicateField is used to duplicate a field
   *
   * @param treeData - the entire tree data
   * @param field - the field you're wanting to duplicate
   * @param parentKey - the key of the parent node, so we can know where to put the duplicated node
   * @param newName - the name of the new node
   * @param newKey - the key of the new node
   */
  duplicateField(treeData: CustomForm, field: FormField, parentKey?: string, newName?: string, newKey?: string) {
    if (!newKey) {
      newKey = makeUniqueFromId(toCamelCase(newName || field.title),
          (key) => this.isUniqueKey(key));
    }
    let results = addNodeUnderParent({
      treeData,
      newNode: {
        title: newName || field.title,
        subtitle: field.type,
        helpText: field.helpText || '',
        paidOptionAmount: field.paidOptionAmount || "",
        key: newKey,
        type: field.type,
        isRequired: field.isRequired,
        validator: field.validator,
        subFieldLabel: field.subFieldLabel,
        subFieldKey: field.subFieldKey,
        autofill: field.autofill,
        multiSelectLimit: field.multiSelectLimit
      },
      parentKey: parentKey,
      getNodeKey: ({node}: { node: TreeNode }) => node.key,
      ignoreCollapsed: false
    });
    treeData = results.treeData;
    (field.children || []).forEach((c: FormField) => {
      treeData = this.duplicateField(treeData, c, newKey, c.title, `${newKey}_${toCamelCase(c.title)}`);
    });
    return treeData;
  }

  /**
   * duplicateNode is used to duplicate any node in the tree. Mainly used to duplicate children of fields in the
   * duplicateField function
   *
   * @param treeData - the entire tree data
   * @param categoryNodeInfo - the node being duplicated
   * @param setValue - the function for saving the data of the new tree
   */
  async duplicateNode(treeData: CustomForm, categoryNodeInfo: NodeInfo, setValue: any) {
    // console.log("TREE DATA", treeData);
    // console.log("CATEGORY NODE INFO", categoryNodeInfo);
    // console.log("SET VALUE", setValue);

    const {name} = await runModalForm(() => <div>
      <CustomFormInput id="name" labelText="Name" autofocus/>
    </div>, {
      title: `Duplicate ${categoryNodeInfo.node.type} ${categoryNodeInfo.node.title}?`,
      noCancel: true,
      submitLabel: 'OK',
      initialState: {
        name: `${categoryNodeInfo.node.title} (Duplicated)`
      }
    });
    treeData = this.duplicateField(treeData, categoryNodeInfo.node as FormField, categoryNodeInfo.parentNode?.key, name);

    setValue(treeData);
    this.setState({dirty: true});
  }

  /**
   * editSelectOption is used for editing or adding a new option to a select input
   *
   * @param title - the title of the modal. Usually 'Edit Select Option' if editing, or 'Add Select Option' if not
   * @param treeData - the entire tree data
   * @param parentNodeInfo - the node data of the parent node. Should be a select or sub-select.
   * @param nodeInfo - the node data of the field you're editing. If set, puts the function in "editing" mode
   * @param save - function for saving the tree data with the new/revised select option
   */
  async editSelectOption({title, treeData, parentNodeInfo, nodeInfo, save}:
                             {
                               title: string,
                               treeData: CustomForm,
                               parentNodeInfo?: NodeInfo,
                               nodeInfo?: NodeInfo,
                               save: any
                             }) {
    const validateOption = ({title: name, type, subFieldLabel, paidOptionAmount = '', fieldMeta}: Option) => {
      if (!name) {
        throw new Error('Name is required');
      }

      if (type === 'paid-option' && isNaN(parseFloat(paidOptionAmount))) {
        throw new Error('Paid Amount must be a number');
      }

      const key = (parentNodeInfo?.node.key || nodeInfo?.parentNode?.key) + '_' + toCamelCase(name);

      // Only check if the name is unique if we're adding a new option
      // or if we're editing a option and they've changed the name
      if ((!nodeInfo || name !== nodeInfo.node.title) && !this.isUniqueKey(key)) {
        throw new Error('This option cannot be generated because it is ' +
            'identical to another already created field. ' +
            'Please try again with a unique option.');
      } else {
        return {title: name, key, subFieldLabel, paidOptionAmount, type: type.toLowerCase(), fieldMeta};
      }
    };

    const {addNewOption, ...newOption} = await runModalForm((props: any) =>
        <FormDataContext.Consumer {...props}>{({data}: any) => <div style={{width: 480}}>
          <CustomFormInput id="title" labelText="Name"/>
          {(data?.type || '').toLowerCase() === 'sub-select' ?
              <CustomFormInput id={'subFieldLabel'} labelText="Sub Select Label"/> : ''}
          <CustomSelectFormInput id="type" labelText="Type" options={[
            {label: 'Option', value: 'option'},
            ...(this.props.showPaidOptions) ? [{label: 'Paid Option', value: 'paid-option'}] : [],
            ...((parentNodeInfo?.node.type || nodeInfo?.parentNode?.type) !== 'multi-select') ? [{
              label: 'Sub-Select',
              value: 'sub-select'
            }] : []]}/>
          {(data?.type || '') === 'paid-option' ?
              <CustomFormInput id="paidOptionAmount" type="number" labelText="Amount"/> : ''}
          <WithFormProp prop="fieldMeta">
            <RenderCustomForm customForm={[{children: this.props.getMetaFields?.(data) || []} as FormCategory]}
                              defaultSm={12}/>
          </WithFormProp>
        </div>}</FormDataContext.Consumer>, {
      title,
      submitLabel: nodeInfo ? 'Save' : 'Add',
      noCancel: true,
      initialState: nodeInfo ? nodeInfo.node : {type: 'option', paidOptionAmount: 0},
      onSubmit: validateOption,
      additionalActions: nodeInfo ? undefined : ({onSubmit, resolve, data}: any) =>
          <Button simple color="primary" onClick={() =>
              resolve({...onSubmit(data), addNewOption: true})
          }>Add &amp; New Option</Button>
    }).catch(() => ({}));

    //console.log(newOption);

    if (newOption.key) {
      if (nodeInfo) {
        const results = changeNodeAtPath({
          treeData,
          getNodeKey: ({node}: { node: TreeNode }) => node.key,
          path: nodeInfo.path,
          newNode: {
            ...newOption,
            subtitle: 'Type: ' + newOption.type + (newOption.type === 'paid-option' ? ' - ' + formatMoney(newOption.paidOptionAmount) : ''),
            subFieldKey: newOption.subFieldLabel ? toCamelCase(newOption.subFieldLabel) : undefined,
            ...(newOption.type === 'sub-select' ? {children: []} : {})
          },
        });
        save(results);
      } else {
        const results = addNodeUnderParent({
          treeData,
          newNode: {
            ...newOption,
            subtitle: 'Type: ' + newOption.type + (newOption.type === 'paid-option' ? ' - ' + formatMoney(newOption.paidOptionAmount) : ''),
            subFieldKey: newOption.subFieldLabel ? toCamelCase(newOption.subFieldLabel) : undefined,
            ...(newOption.type === 'sub-select' ? {children: []} : {})
          },
          parentKey: parentNodeInfo?.node.key,
          getNodeKey: ({node}: { node: TreeNode }) => node.key,
          expandParent: true,
        });
        save(results.treeData);
        if (addNewOption) {
          await this.editSelectOption({
            title: 'Add Option',
            treeData: results.treeData,
            parentNodeInfo,
            save
          });
        }
      }
    }
  }

  /**
   * toggleIsRequired sets the isRequired field on a specific node. This is used for making the field required on the user's side
   *
   * @param treeData - the entire tree data
   * @param nodeInfo - the node you want to toggle isRequired on. Should be an input field, not a category or select option.
   * @param setValue - function for saving the tree data with the field toggled
   */
  toggleIsRequired(treeData: CustomForm, nodeInfo: { node: TreeNode, path: number[] | string[] }, setValue: any) {
    const results = changeNodeAtPath({
      treeData,
      getNodeKey: ({node}: { node: TreeNode }) => node.key,
      path: nodeInfo.path,
      newNode: {
        ...nodeInfo.node,
        isRequired: !nodeInfo.node.isRequired,
      },
    });
    setValue(results);
    this.setState({dirty: true});
  }

  /**
   * showPreview shows a preview of the form you're creating in a modal. Good for the user to see what they've made.
   */
  async showPreview() {
    const {value} = this.props;
    //console.log("value", value);
    await runModalForm(() => <div style={{width: 580, maxWidth: '100%'}}>
      <RenderCustomForm customForm={value || []}/>
    </div>, {
      title: 'Preview',
      noCancel: true,
      submitLabel: 'Close',
      session: this.props.session,
    }).catch(() => {
    });
  }

  render() {
    const {value, classes} = this.props,
        onChange = (v: any) => this.props.onChange(v);

    return (<div>
          <div style={{
            height: getVisibleNodeCount({treeData: value}) * 62,
            width: '100%',
            marginTop: '1rem',
          }}>
            <SortableTree
                theme={Theme}
                treeData={value}
                onChange={(treeData: CustomForm) => onChange(treeData)}
                getNodeKey={({node}: { node: FormField }) => node.key}
                canDrop={({node, prevParent, nextParent}: {
                  node: TreeNode, prevParent: TreeNode, nextParent: TreeNode
                }) => {
                  switch (node.type) {
                    case 'category':
                      return !nextParent;
                    case 'text':
                    case 'date':
                    case 'multi-select':
                    case 'select':
                    case 'file':
                      return !!nextParent && nextParent.type === 'category';
                    case 'sub-select':
                    case 'paid-option':
                    case 'option':
                      return !!nextParent && prevParent.title ===
                          nextParent.title;
                    default:
                      return false;
                  }
                }}
                // isVirtualized={false}
                generateNodeProps={(rowInfo: NodeInfo) => {
                  return {
                    style: {
                      cursor: 'default',
                    },
                    title: ({node}: { node: TreeNode }) => {
                      const {title}: { title: string } = node;
                      return <span title={title} style={{
                        maxWidth: '250px',
                        display: 'inline-block',
                        overflowX: 'hidden',
                        textOverflow: 'ellipsis'
                      }}>{title}</span>;
                    },
                    buttons: rowInfo.node.type === 'category' ? [
                      <Tooltip title="Duplicate Category"><Button
                          color="transparent" size="sm" justIcon
                          onClick={() => this.duplicateNode(value, rowInfo, onChange)}><FileCopy/></Button></Tooltip>,
                      <Tooltip title="Add Field in Category"><Button
                          color="transparent"
                          size="sm"
                          justIcon
                          onClick={() => this.editFormField({
                            title: 'Add Field',
                            treeData: value,
                            parentNodeInfo: rowInfo,
                            save: onChange
                          })}
                      ><Add/></Button></Tooltip>,
                      <Tooltip title="Edit Category"><Button
                          color="transparent"
                          size="sm"
                          justIcon
                          onClick={() => this.editCategory({
                            title: 'Edit Category',
                            treeData: value,
                            nodeInfo: rowInfo,
                            save: onChange
                          })}
                      ><Edit/></Button></Tooltip>,
                      !rowInfo.node.fixed ? <Tooltip title="Delete Category"><Button
                          color="transparent"
                          size="sm"
                          justIcon
                          disabled={rowInfo.node.fixed}
                          onClick={() => this.deleteCategory({
                            treeData: value,
                            nodeInfo: rowInfo,
                            save: onChange
                          })}
                      ><Delete/></Button></Tooltip> : '',
                    ] : rowInfo.node.type?.endsWith('select') ? [
                      ...(rowInfo.node.type !== 'sub-select' ? [
                        <div className={classes.checkboxAndRadio + ' ' +
                            classes.checkboxAndRadioHorizontal}>
                          <FormControlLabel
                              classes={{label: classes.label}}
                              label="Required"
                              control={
                                <Checkbox
                                    tabIndex={-1}
                                    checked={rowInfo.node.isRequired}
                                    onClick={() => this.toggleIsRequired(value, rowInfo, onChange)}
                                    checkedIcon={<Check className={classes.checkedIcon}/>}
                                    icon={<Check className={classes.uncheckedIcon}/>}
                                    classes={{
                                      checked: classes.checked,
                                      root: classes.checkRoot,
                                    }}/>}/>
                        </div>] : []),
                      <Tooltip title="Duplicate Select"><Button
                          color="transparent" size="sm" justIcon
                          onClick={() => this.duplicateNode(value, rowInfo, onChange)}><FileCopy/></Button></Tooltip>,
                      <Tooltip title="Add Select Option"><Button
                          color="transparent"
                          size="sm"
                          justIcon
                          onClick={() => this.editSelectOption({
                            title: 'Add New Option',
                            treeData: value,
                            parentNodeInfo: rowInfo,
                            save: onChange
                          })}
                      ><Add/></Button></Tooltip>,
                      <Tooltip title="Edit Select"><Button
                          color="transparent"
                          size="sm"
                          justIcon
                          onClick={() => this.editFormField({
                            title: 'Edit Form Field',
                            treeData: value,
                            nodeInfo: rowInfo,
                            save: onChange
                          })}
                      ><Edit/></Button></Tooltip>,
                      !rowInfo.node.fixed ? <Tooltip title="Delete Select"><Button
                          color="transparent"
                          size="sm"
                          justIcon
                          disabled={rowInfo.node.fixed}
                          onClick={() => this.deleteCategory({
                            treeData: value,
                            nodeInfo: rowInfo,
                            save: onChange
                          })}
                      ><Delete/></Button></Tooltip> : '',
                    ] : rowInfo.node.type === 'option' || rowInfo.node.type === 'paid-option' ? [
                          <Tooltip title="Duplicate Option"><Button
                              color="transparent" size="sm" justIcon
                              onClick={() => this.duplicateNode(value, rowInfo, onChange)}><FileCopy/></Button></Tooltip>,
                          <Tooltip title="Edit Option"><Button
                              color="transparent"
                              size="sm"
                              justIcon
                              onClick={() => this.editSelectOption({
                                title: 'Edit Option',
                                treeData: value,
                                nodeInfo: rowInfo,
                                save: onChange
                              })}
                          ><Edit/></Button></Tooltip>,
                          !rowInfo.node.fixed ? <Tooltip title="Delete Option"><Button
                              color="transparent"
                              size="sm"
                              justIcon
                              disabled={rowInfo.node.fixed}
                              onClick={() => this.deleteCategory({
                                treeData: value,
                                nodeInfo: rowInfo,
                                save: onChange
                              })}
                          ><Delete/></Button></Tooltip> : '',
                        ]
                        : [
                          <div className={classes.checkboxAndRadio + ' ' +
                              classes.checkboxAndRadioHorizontal}>
                            <FormControlLabel
                                classes={{label: classes.label}}
                                label="Required"
                                control={
                                  <Checkbox
                                      tabIndex={-1}
                                      checked={rowInfo.node.isRequired}
                                      onClick={() => this.toggleIsRequired(
                                          value, rowInfo,
                                          onChange)}
                                      checkedIcon={<Check
                                          className={classes.checkedIcon}/>}
                                      icon={<Check
                                          className={classes.uncheckedIcon}/>}
                                      classes={{
                                        checked: classes.checked,
                                        root: classes.checkRoot,
                                      }}/>}/>
                          </div>,
                          <Tooltip title="Duplicate Field"><Button
                              color="transparent" size="sm" justIcon
                              onClick={() => this.duplicateNode(value, rowInfo, onChange)}><FileCopy/></Button></Tooltip>,
                          <Tooltip title="Edit Field"><Button
                              color="transparent"
                              size="sm"
                              justIcon
                              onClick={() => this.editFormField({
                                title: 'Edit Form Field',
                                treeData: value,
                                nodeInfo: rowInfo,
                                save: onChange
                              })}
                          ><Edit/></Button></Tooltip>,
                          !rowInfo.node.fixed ? <Tooltip title="Delete Field"><Button
                              color="transparent"
                              size="sm"
                              justIcon
                              disabled={rowInfo.node.fixed}
                              onClick={() => this.deleteCategory({
                                treeData: value,
                                nodeInfo: rowInfo,
                                save: onChange
                              })}
                          ><Delete/></Button></Tooltip> : '',
                        ],
                  };
                }}
            />
          </div>
          <Button color="success"
                  onClick={this.editCategory.bind(this, {
                    title: 'Add Category',
                    treeData: value,
                    save: onChange
                  })}>
            Add Category</Button>
          <Button color="primary" simple startIcon={<Visibility/>}
                  onClick={this.showPreview.bind(this)}>
            Preview Form</Button>
        </div>
    );
  }
}

export default withStyles(style as any)(withSession(CustomFormEditor));

export const CustomFormEditorInput = withStyles(style as any)((props: Partial<Props> & { id: string }) =>
    <FormDataContext.Consumer>{({data, onChange}: any) =>
        <CustomFormEditor {...(props as Props)} value={data[props.id] || []}
                          onChange={newValue => onChange({...data, [props.id]: newValue})}/>}
    </FormDataContext.Consumer>
);

/**
 * calculateFormCost goes through each field and calculates how much the user needs to pay based on which paid options they've chosen
 *
 * @param regData - registration data from an enrollment or event
 * @param form - the tree data of the form
 */
export function calculateFormCost(regData: any, form: CustomForm): { total: number, fields: any } {
  let total = 0;
  let fields: Record<string, number> = {};

  if (!regData || !form) {
    return {total, fields};
  }

  Object.keys(regData).forEach(key => {
    let {matches} = find({
          treeData: form,
          getNodeKey: ({node}: { node: any }) => node.key,
          searchMethod: ({node}: { node: any }) => node.key === key,
          searchFocusOffset: 0
        }),
        selectNode = matches.length && matches[0]?.node;
    if (selectNode?.type === 'multi-select' && Array.isArray(regData[key])) {
      regData[key].forEach((option: string) => {
        let selectedOption = selectNode.children.find((child: any) => child.key === selectNode.key + '_' + toCamelCase(option));
        if (selectedOption?.type === 'paid-option') {
          total += parseFloat(selectedOption.paidOptionAmount);
          fields[`${selectNode.title} - ${selectedOption.title}`] = parseFloat(selectedOption.paidOptionAmount);
        }
      });
    }
    if (selectNode?.type === 'select') {
      let selectedNode = selectNode.children.find((child: any) => child.key === selectNode.key + '_' + toCamelCase(regData[key]));
      if (selectedNode?.type === 'paid-option' && selectedNode?.paidOptionAmount) {
        total += parseFloat(selectedNode.paidOptionAmount);
        fields[`${selectNode.title} - ${selectedNode.title}`] = parseFloat(selectedNode.paidOptionAmount);
      }
    }
  })

  return {total, fields};
}

/**
 * makeUniqueFromId tries to make a unique key from the given ID. It does this by tallying up the already
 * existing keys, and adding a number to the end of it
 *
 * @param existingId - id to test
 * @param isUnique - function for testing if the id is unique
 */
function makeUniqueFromId(existingId: string, isUnique: (str: string) => boolean) {
  let newValue = existingId, number = 1;
  while (!isUnique(newValue)) {
    newValue = `${existingId}_${number++}`;
  }
  return newValue;
}

/**
 * listAllFields lists all of the input fields on a CustomForm object. This is used to show the options on the
 * "Is Conditionally Required" section on adding/editing a form field
 *
 * @param treeData - the entire tree data
 */

export function listAllFields(treeData: CustomForm) {

  let categoryTitle = '';

  function listSubFields(node: TreeNode, parents?: TreeNode[]): {
    id: string, name: string, subSelects?: { key: string, value: string }[]
  }[] {
    const parent = parents?.length ? parents[parents.length - 1] : undefined;
    if (['option', 'paid-option'].includes(node.type)) {
      return [];
    } else if (node.type === 'category') {
      categoryTitle = node.title;
      return node.children?.map(c => listSubFields(c)).reduce((a, b) => a.concat(b), []);
    } else if (['select', 'sub-select'].includes(node.type)) {
      return (node.children || []).reduce((a, c) =>
          a.concat(listSubFields(c, (parents || []).concat([node]))), [
        {
          id: node.subFieldKey || node.key,
          name: parent ? `${categoryTitle}: ${parent.title} - ${node.title}` : `${categoryTitle}: ${node.title}`,
          subSelects: getParentSubSelects(node, parents),
        } as { id: string, name: string, subSelects?: { key: string, value: string }[] }
      ]);
    } else {
      return [
        {id: node.key, name: `${categoryTitle}: ${node.title}`} as {
          id: string, name: string, subSelects?: { key: string, value: string }[]
        }
      ];
    }
  }

  return treeData.map(e => listSubFields(e)).reduce((a, b) => a.concat(b), []);
}

function getParentSubSelects(node: TreeNode, parents?: TreeNode[]): {key: string, value: string}[] {
  const subSelects = [];
  if(!parents?.length) {
    return [];
  }
  const hierarchy = parents.concat([node]);
  for(let i = 0; i < (hierarchy.length - 1); i++) {
    subSelects.push({key: hierarchy[i].subFieldKey || hierarchy[i].key, value: hierarchy[i + 1].title});
  }
  return subSelects;
}

// export function listAllFields(treeData: CustomForm) {
//
//     function listSubFields(node: TreeNode): { id: string, name: string }[] {
//         if (['option', 'paid-option'].includes(node.type)) {
//             return [];
//         } else if (node.type === 'category') {
//             return node.children.map(listSubFields)
//                 .reduce((a, b) => a.concat(b), []);
//         } else if(['select', 'sub-select'].includes(node.type)) {
//             return node.children.reduce((a, c) => a.concat(listSubFields(c)), [
//                 {
//                     id: (node.subFieldKey || node.key), name: node.title,
//                 }]);
//         } else {
//             return [{id: node.key, name: node.title}];
//         }
//     }
//
//     return treeData.map(listSubFields).reduce((a, b) => a.concat(b), []);
// }

/**
 * findNode is for finding a node with a particular key
 *
 * @param treeData - the entire tree data
 * @param key - the key of the node you want to find
 */
function findNode(treeData: CustomForm, key: string) {
  let {matches} = find({
        treeData,
        getNodeKey: ({node}: { node: any }) => node.key,
        searchMethod: ({node}: { node: any }) => node.key === key,
        searchFocusOffset: 0
      }),
      node: TreeNode = matches.length && matches[0]?.node;

  return node;
}
